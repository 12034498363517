import React from "react";
import { Alert, Button, Grid, Modal, ModalClose, ModalDialog, Typography } from "@mui/joy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

type ConfirmationDialogProps = {
	open: boolean;
	isLoading?: boolean;
	isError?: boolean;
	errorMessage?: string;
	subtitle: string;
	onClose: () => void;
	onClick: () => void;
}

function ConfirmationDialog (props: ConfirmationDialogProps) {
	const {
		open,
		subtitle,
		isLoading,
		isError,
		errorMessage,
		onClose,
		onClick
	} = props;

	const handleClose = (event: any, reason: string) => {
		if (reason === "backdropClick") {
			return false;
		}

		if (typeof onClose === "function") {
			onClose();
		}

		onClose();
		return true;
	};

	return (
		<Modal
			open={open}
			onClose={handleClose}
			disableEscapeKeyDown
			role="alertdialog"
		>
			<ModalDialog
				aria-labelledby="confirmation-modal-dialog-title"
				aria-describedby="confirmation-modal-dialog-description"
				sx={{ maxWidth: 500 }}
			>
				{isError && <Alert variant="soft" color="danger" sx={{ marginBottom: "0.5rem" }}>
					<Typography color="danger" fontWeight="md">
						{errorMessage || "Oops! Something went wrong. Try again"}
					</Typography>
				</Alert>}
				<Grid container direction={"row"} alignItems="center">
					<FontAwesomeIcon icon={faExclamationTriangle} style={{ fontSize: "1.5rem", color: "var(--joy-palette-warning-400)", marginRight: "1rem" }}/>
					<Typography component="h2" id="close-modal-title" level="h4" textColor="inherit">
					Are you sure?
					</Typography>
				</Grid>
				<ModalClose variant="outlined" />
				<Typography id="confirmation-modal-dialog-description">
					{subtitle}
				</Typography>
				<Typography id="confirmation-modal-dialog-body-1" >
					This is a permanent action and your GP will be notified.
				</Typography>
				<Typography id="confirmation-modal-dialog-body-1" marginTop={"1rem"}>
					You will not have access to this task again unless your GP resends this task.
				</Typography>
				<Grid container flexDirection={"row"} marginTop={"1rem"} justifyContent={"flex-end"}>
					<Button
						variant="plain"
						color="neutral"
						onClick={onClose}
						disabled={isLoading}
						sx={{ marginRight: "1rem" }}
					>
						Cancel
					</Button>
					<Button
						variant="solid"
						color="danger"
						onClick={onClick}
						loading={isLoading}
						disabled={isLoading}
					>
						Decline
					</Button>
				</Grid>
			</ModalDialog>
		</Modal>
	);
}

export default ConfirmationDialog;