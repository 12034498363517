import * as Sentry from "@sentry/nextjs";
import { useUserAuthStore } from "store";
import { protectedPatientApiClient } from "./patientApiClient";
import { IPatientApiGetFormTemplateResponse, IPatientApiListTaskResponse, IPatientApiParseShortUrl, IPatientApiSaveFormResponse } from "@careiq-health/patient-types";
import { TaskStatus } from "@careiq-health/biomarker-utils";
import { ISubmitFormResult, IDeclineFormResult } from "@types";

export const getPendingTasks = async (): Promise<IPatientApiListTaskResponse | undefined> => {
	const { userInfo } = useUserAuthStore.getState();

	try {
		return protectedPatientApiClient.get(`/v1/tasks`, {
			params: {
				patientId: userInfo?.userPatients?.[0]?.patientId
			}
		}).then((response) => response.data);
	} catch (error) {
		Sentry.captureMessage(`Error! getPendingTasks`);
		Sentry.captureException(error);
	}

	return undefined;
};

export const getLongUrl = async (shortUrl: string): Promise<IPatientApiParseShortUrl | any> => {
	try {
		return fetch("/api/task/getLongUrl", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			mode: "same-origin",
			body: JSON.stringify({
				shortUrl
			})
		}).then(async (response) => await response.json());

	} catch (error) {
		Sentry.captureMessage(`Error! getLongUrl`);
		Sentry.captureException(error);
	}
};

// @ts-ignore
export const getFormTemplate = async (formTemplateId: string): Promise<IPatientApiGetFormTemplateResponse | undefined> => {
	try {
		return protectedPatientApiClient.get(`/v1/templates/forms/${formTemplateId}`).then((response) => response.data);
	} catch (error) {
		Sentry.captureMessage(`Error! getFormTemplate`);
		Sentry.captureException(error);
	}
};

// @ts-ignore
export const submitFormResults = async (props: ISubmitFormResult): Promise<IPatientApiSaveFormResponse | undefined> => {
	const requestPayload = {
		...props,
		status: TaskStatus.Completed
	};

	try {
		return protectedPatientApiClient.post(`/v1/timeline/form`, requestPayload);
	} catch (error) {
		Sentry.captureMessage(`Error! submitFormResults`);
		Sentry.captureException(error);
	}
};

export const getPdfFileFromTemplate = async (taskId: string): Promise<any | undefined> => {
	try {
		return protectedPatientApiClient.post(`/v1/templates/forms/${taskId}/pdf`, {
			taskId
		});
	} catch (error) {
		Sentry.captureMessage(`Error! getPdfFileFromTemplate`);
		Sentry.captureException(error);
	}
};

// @ts-ignore
export const declineFormResults = async (props: IDeclineFormResult): Promise<IPatientApiSaveFormResponse | undefined> => {
	const requestPayload = {
		...props,
		details: { description: "Declined by patient" },
		status: TaskStatus.Declined
	};

	try {
		return protectedPatientApiClient.post(`/v1/timeline/form`, requestPayload);
	} catch (error) {
		Sentry.captureMessage(`Error! declineFormResults`);
		Sentry.captureException(error);
	}
};