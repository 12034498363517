import React from "react";
import { Grid, Typography, Button, Card, Avatar, Skeleton, AspectRatio } from "@mui/joy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBallotCheck, faUserDoctor } from "@fortawesome/pro-solid-svg-icons";
import MuiLinkify from "components/muiLinkify";

interface ITaskProps {
	senderName: string;
	title: string;
	body: string;
	onContinueClick: () => void;
	onDeclineClick: () => void;
}

function Task (props: ITaskProps) {
	const { senderName, title, body, onContinueClick, onDeclineClick } = props;

	return (
		<Grid margin="1rem">
			<Grid container direction="row" alignItems={"center"} marginBottom="0.5rem">
				<Avatar size="sm" sx={{ backgroundColor: "brand.darkBackground" }} alt="Healthcare professional icon">
					<FontAwesomeIcon icon={faUserDoctor} style={{ fontSize: "1rem", color: "white" }}/>
				</Avatar>
				<Typography marginLeft={"1rem"}>{senderName}</Typography>
			</Grid>
			<Card variant="outlined">
				<Grid container direction="row" alignItems={"center"} marginBottom="0.5rem">
					<Avatar sx={{ backgroundColor: "icon.document" }} alt="Healthcare professional icon">
						<FontAwesomeIcon icon={faBallotCheck} style={{ fontSize: "1rem", color: "white" }}/>
					</Avatar>
					<Typography level="h4" marginLeft={"1rem"}>{title}</Typography>
				</Grid>
				<MuiLinkify LinkProps={{ target: "_blank", rel: "noopener noreferrer" }}>
					<Typography maxWidth={"30rem"} marginBottom="2rem" whiteSpace={"pre-line"} sx={{ wordWrap: "break-word", wordBreak: "break-word" }}>
						{body}
					</Typography>
				</MuiLinkify>
				<Button variant="solid" onClick={onContinueClick} >Start</Button>
				<Button variant="plain" color="danger" onClick={onDeclineClick} sx={{ marginTop: "0.5rem" }}>Decline</Button>
			</Card>
		</Grid>
	);
}

export function TaskSkeleton () {
	return (
		<Grid maxWidth={{ md: "32rem" }} margin="1rem">
			<Grid container direction="row" alignItems={"center"} marginBottom="0.5rem">
				<Avatar size="sm"><Skeleton></Skeleton></Avatar>
				<Typography marginLeft={"1rem"}><Skeleton>Blood Test for Atrial Fibrillation</Skeleton></Typography>
			</Grid>
			<Card variant="outlined">
				<Grid container direction="row" alignItems={"center"} marginBottom="0.5rem">
					<Avatar><Skeleton></Skeleton></Avatar>
					<Typography level="h4" marginLeft={"1rem"}><Skeleton>Blood Test for Atrial Fibrillation</Skeleton></Typography>
				</Grid>
				<Typography>
					<Skeleton>
						Lorem ipsum is placeholder text commonly used in the graphic, print, and
						publishing industries. Lorem ipsum is placeholder text commonly used in the graphic, print, and
						publishing industries.
					</Skeleton>
				</Typography>
				<Typography marginBottom={"2rem"}>
					<Skeleton>
						Lorem ipsum is placeholder text commonly used in the graphic, print, and
						publishing industries.
					</Skeleton>
				</Typography>

				<Button variant="solid"><Skeleton>Start</Skeleton></Button>
				<Button variant="solid"><Skeleton>Decline</Skeleton></Button>
			</Card>
		</Grid>
	);
}

export default Task;