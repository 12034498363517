import axios from "axios";
import dayjs from "dayjs";
import { getServerUrl } from "../config";
import { useUserAuthStore } from "../store";
import { logout, refreshAccessToken } from "./authService";

// For public endpoints
export const publicPatientApiClient = axios.create({
	baseURL: `${getServerUrl()}`,
	timeout: 5000
});
let isRefreshing = false;
let refreshPromise: Promise<string> | null = null;

publicPatientApiClient.defaults.headers.common["Content-Type"] = "application/json";

publicPatientApiClient.interceptors.response.use((response) => {
	return response;
},
async (error) => {
	const originalRequest = error.config;

	// https://stackoverflow.com/a/51599698
	if ((error?.response?.status !== 200 || error?.response?.status !== 201) && !originalRequest._retry) {

		throw error?.response?.data?.data?.errors?.length > 0 ?
			error?.response?.data?.data?.errors[0] : {
				code: error?.response?.status,
				title: error?.response?.statusText
			};
	}

	return Promise.reject(error);
});

// For private endpoints
export const protectedPatientApiClient = axios.create({
	baseURL: `${getServerUrl()}`
	// withCredentials: true
});
protectedPatientApiClient.defaults.headers.common["Content-Type"] = "application/json";

protectedPatientApiClient.interceptors.request.use(async (config) => {
	const { authInfo, updateTokens, removeAuthentication } = useUserAuthStore.getState();
	let response;

	// Refresh token if expired
	if (authInfo?.refresh_token && authInfo?.expires_at && dayjs().isAfter(dayjs(authInfo.expires_at))) {
		response = await refreshAccessToken(authInfo.refresh_token);

		if (response === undefined) {
			logout();
			removeAuthentication();
		}

		updateTokens(response?.data?.tokenResponse);
	}
	const token = response?.data?.tokenResponse?.access_token || authInfo?.access_token;

	config.headers["Authorization"] = `Bearer ${token}`;

	return config;
}, async (error) => {
	const { authInfo } = useUserAuthStore.getState();
	const originalRequest = error.config;

	if (error.response.status === 401 && !originalRequest._retry) {
		originalRequest._retry = true;

		if (!isRefreshing) {
			isRefreshing = true;
			refreshPromise = refreshAccessToken(authInfo?.refresh_token || "");
		}

		const newBearerToken = await refreshPromise;
		originalRequest.headers["Authorization"] = `Bearer ${newBearerToken}`;
		isRefreshing = false;
		return protectedPatientApiClient(originalRequest);
	}

	return Promise.reject(error);
});

protectedPatientApiClient.interceptors.response.use((response) => {
	return response;
},
async (error) => {
	const { authInfo, updateTokens, removeAuthentication } = useUserAuthStore.getState();
	const originalRequest = error.config;

	if ((error?.response?.status !== 200 || error?.response?.status !== 201) && !originalRequest._retry) {
		let response;
		originalRequest._retry = true;

		if (authInfo?.refresh_token && error?.response?.status === 401) {
			response = await refreshAccessToken(authInfo?.refresh_token);

			if (response === undefined) {
				logout();
				removeAuthentication();
			}

			updateTokens(response?.data?.tokenResponse);
		}

		throw error?.response?.data?.errors?.[0];
	}
	return Promise.reject(error);
});
