import * as Sentry from "@sentry/nextjs";
import React from "react";
import { Grid, Typography } from "@mui/joy";
import { useRouter } from "next/router";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { IDescriptivePatientTask } from "@careiq-health/patient-types";
import { PrismaTenantJsonTypes } from "@careiq-health/core-types/index";

import Task, { TaskSkeleton } from "components/task";
import ConfirmationDialog from "components/dialog/confirmationDialog";
import { centreOfPageAlt } from "../../styles/shared";
import { declineFormResults, getPendingTasks } from "../../services/patientService";
import { toTitleCase } from "../../utils";
import { IDeclineFormResult } from "@types";

function HomeLayout () {
	const router = useRouter();
	const queryClient = useQueryClient();

	const [ isConfirmationDialogOpen, setIsConfirmationDialogOpen ] = React.useState(false);
	const [ confirmationDialogTask, setConfirmationDialogTask ] = React.useState<IDescriptivePatientTask | null>(null);

	const { data: pendingTaskResponse, isLoading: pendingTaskIsLoading, isError: pendingTaskIsError, error: pendingTaskError } = useQuery(([ "getPendingTasks" ]), () => getPendingTasks());

	const handleConfirmationDialogClose = () => {
		setIsConfirmationDialogOpen(false);
		setConfirmationDialogTask(null);
	};

	// mutation to submit form. after successful submission go to landing page
	const declineFormResultSubmission = useMutation(({ formTemplateId, formTemplateName, taskId }: IDeclineFormResult) => declineFormResults({ formTemplateId, formTemplateName, taskId }), {
		onSuccess: (response: any) => {
			handleConfirmationDialogClose();
			queryClient.invalidateQueries({ queryKey: [ "getPendingTasks" ] });
		},
		onError: (error: {title: string}) => {
			Sentry.captureMessage(`Error! getPendingTasks`);
			Sentry.captureException(error);
		}
	});

	const handleDeclineTask = () => {
		declineFormResultSubmission.mutate({
			// @ts-ignore
			formTemplateId: confirmationDialogTask?.taskDetails?.questionnaireId || confirmationDialogTask?.taskDetails?.bloodTestFormTemplateId || "",
			formTemplateName: confirmationDialogTask?.name || "",
			taskId: confirmationDialogTask?.id || ""
		});
	};


	return (
		<Grid sx={{
			...centreOfPageAlt
		}}>
			<Typography level="h4" marginTop="1rem" marginBottom="1rem">My tasks</Typography>
			{pendingTaskIsLoading && <>
				<TaskSkeleton />
				<TaskSkeleton />
			</>}
			{(pendingTaskResponse?.length === 0) && <Typography level="body-md" marginBottom="0.5rem">You&apos;re all caught up! 🎉</Typography>}
			<>
				{
					pendingTaskResponse?.map((item) => {
						// e.g. Dr H. Smith
						const senderName = `${toTitleCase(item.requestedByTitle as string)} ${(item.requestedByFirstName as string).charAt(0).toUpperCase()}. ${item.requestedByLastName}`;
						const taskDetails = item?.taskDetails as unknown as PrismaTenantJsonTypes.ITaskDetails;

						return (
							<Task
								key={item.id}
								senderName={senderName}
								title={item.name}
								body={item?.description}
								onContinueClick={() => router.push({
									pathname: "/form/[id]",
									query: {
										// @ts-ignore
										id: taskDetails?.questionnaireId || taskDetails?.bloodTestFormTemplateId,
										taskId: item?.id,
										taskType: item?.taskType
									}
								})}
								onDeclineClick={() => {
									setIsConfirmationDialogOpen(true);
									setConfirmationDialogTask(item);
								}}
							/>
						);
					})
				}
				<ConfirmationDialog
					open={isConfirmationDialogOpen}
					subtitle={`Your ${confirmationDialogTask?.name} will be declined.`}
					isLoading={declineFormResultSubmission.isLoading}
					isError={declineFormResultSubmission.isError}
					errorMessage={declineFormResultSubmission.error?.title}
					onClose={handleConfirmationDialogClose}
					onClick={handleDeclineTask}
				/>
			</>

		</Grid>
	);
}

export default HomeLayout;